import {hsl} from 'd3';

const goldenRatio = 1.61803398875;
const inverseGoldenRatio = 1 / goldenRatio;

class RandomColorGenerator {
  constructor(seedVal, colorFunc) {
    this.seedVal = seedVal || Math.random();
    this.value = seedVal;
    this.colorFunc =
      colorFunc ||
      (val => {
        return hsl(val * 360, 1, 0.5);
      });
    this.cache = {};
  }

  next(key) {
    let color = null;
    if (key && key in this.cache) {
      color = this.cache[key];
    } else {
      this.value += inverseGoldenRatio;
      this.value %= 1;
      color = this.colorFunc(this.value);
    }
    if (key) {
      this.cache[key] = color;
    }
    return color;
  }
}

export default RandomColorGenerator;
